<template>
  <div class="onlineSales">
    <crumbs :item="title" />
    <div class="proprietaryNetwork">
      <div>自营网络平台信息</div>
      <ul>
        <li>
          <p>安联在线保</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
        <li>
          <p>安联在线保:</p>
          <p>安联在线</p>
        </li>
        <li>
          <p>安联在线保:</p>
          <p>安联旅行与健康</p>
        </li>
        <li>
          <p>安联在线保</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
      </ul>
    </div>
    <div class="main">
      <p>公司信息</p>
      <ul>
        <li>
          <img
            src="@/static/images/secondaryMenu/about/ic_info_01@2x.png"
            alt=""
          />
          <p>营业执照、法人许可证</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
        <li>
          <img
            src="@/static/images/secondaryMenu/about/ic_info_02@2x.png"
            alt=""
          />
          <p>营业执照、法人许可证</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
        <li>
          <img
            src="@/static/images/secondaryMenu/about/ic_info_03@2x.png"
            alt=""
          />
          <p>营业执照、法人许可证</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
        <li>
          <img
            src="@/static/images/secondaryMenu/about/ic_info_04@2x.png"
            alt=""
          />
          <p>营业执照、法人许可证</p>
          <img src="@/static/images/ic_arrow_black@2x.png" alt="" />
        </li>
      </ul>
    </div>
    <div class="cooperation">
      <p class="onlineSales_title">合作保险机构信息披露</p>
      <div class="table">
        <el-table
          :data="tableData"
          :border="false"
          height="500px"
          style="width: 100%"
          :header-cell-style="tableHeaderColor"
        >
          <el-table-column prop="date" label="合作伙伴"> </el-table-column>
          <el-table-column prop="name" label="合作范围"> </el-table-column>
          <el-table-column prop="address" label="合作起始时间">
          </el-table-column>
          <el-table-column prop="address" label="合作终止时间">
          </el-table-column>
        </el-table>
      </div>
    </div>
    <div class="cooperation internet">
      <p class="onlineSales_title">互联网保险产品信息</p>
      <div class="table">
        <ul class="ul">
          <li v-for="(item, index) in internet" :key="index">
            <div>{{ item.title }}</div>
            <p v-for="items in item.text" :key="items[0]">{{ items }}</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="validation">
      <div class="validation_box">
        <div class="onlineSales_title">互联网产品保单查询及验真</div>
        <p>
          线上销售的所有产品默认提供电子保单，客户付款完成后发送至投保时所填写的电子邮箱，也可以通过以下方式查询及下载
        </p>
        <ul>
          <li>
            <div><span>01</span></div>
            <p>关注“安联在线”微信公众号，使用自助服务查询</p>
          </li>
          <li>
            <div><span>02</span></div>
            <p>在线查询</p>
          </li>
          <li>
            <div><span>03</span></div>
            <p>拨打客服热线<span class="phone">95342</span>查询</p>
          </li>
        </ul>
        <div class="view_btn">
          <div class="btn">
            <p>立即在线查询</p>
          </div>
          <p>
            我司保单已在上海保险交易所托管。请至保交所一户通网站，或下载上海保交所APP或搜索微信公众号“上海保交所”进行保单查验。
          </p>
        </div>
      </div>
    </div>
    <div class="way">
      <div class="way_box">
        <div>
          <p>服务及投诉维权途径</p>
          <p>全国统一客户服务热线和消费者维权热线</p>
          <p class="phone">95342</p>
        </div>
        <img
          src="@/static/images/secondaryMenu/about/bg_contact@2x.png"
          alt=""
        />
        <div class="view_btn">
          <div class="btn">
            <p>客户投诉渠道和投诉处理流程</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
const internet = [
  {
    title: "产品计划名称",
    text: [
      "联安行万里境外旅行互联网意外伤害保险",
      "安联安康 e 生互联网医疗保险",
      "安联附加特种药品费用互联网医疗保险",
      "安联安顺畅游III互联网意外伤害保险",
      "安联至臻互联网医疗保险",
    ],
  },
  {
    title: "产品计划名称",
    text: [
      "联安行万里境外旅行互联网意外伤害保险",
      "安联安康 e 生互联网医疗保险",
      "安联附加特种药品费用互联网医疗保险",
      "安联安顺畅游III互联网意外伤害保险",
      "安联至臻互联网医疗保险",
    ],
  },
  {
    title: "产品计划名称",
    text: [
      "联安行万里境外旅行互联网意外伤害保险",
      "安联安康 e 生互联网医疗保险",
      "安联附加特种药品费用互联网医疗保险",
      "安联安顺畅游III互联网意外伤害保险",
      "安联至臻互联网医疗保险",
    ],
  },
];
export default {
  name: "onlineSales",
  data() {
    return {
      title: [
        {
          title: "网上销售平台",
          link: "/azContent/onlineSales",
        },
      ],
      tableData: [
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-08",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-06",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-07",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
      ],
      internet,
    };
  },
  components: { crumbs },
  created() {
  },
  methods: {
    //设置表头行的样式
    tableHeaderColor() {
      // console.log(row, column, rowIndex, columnIndex);
      return "background-color:#003781;color:#fff;font-size:16px;text-align:center";
    },
  },
};
</script>

<style lang="less" scoped>
li {
  list-style: none;
}
.onlineSales {
  .proprietaryNetwork {
    width: 80%;
    margin: auto;
    text-align: center;
    > div {
      font-size: 3rem;
      margin: 6rem 0;
    }
    > ul {
      display: flex;
      justify-content: space-between;
      margin: 4rem 0 8rem;
      > li {
        display: flex;
        width: 24%;
        border: 1px solid rgba(138, 142, 218, 0.7);
        padding: 3rem 2rem;
        color: #003781;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        cursor: pointer;

        > img {
          width: 16px;
          height: 12px;
          margin-left: 4rem;
        }
        > p:last-child {
          margin-left: 1rem;
        }
      }
    }
  }
  .main {
    width: 100%;
    height: 27vw;
    background-color: #003781;
    padding: 1px;
    > p {
      font-size: 4rem;
      text-align: center;
      color: #fff;
      margin: 7rem 0;
    }
    > ul {
      width: 80%;
      height: 15vw;
      margin: auto;
      display: flex;
      justify-content: space-between;
      li {
        width: 23%;
        border: 1px solid;
        height: 100%;
        background-color: #fff;
        padding: 3rem;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        font-size: 2rem;
        > img:first-child {
          width: 9rem;
          height: 9rem;
        }
        > img:last-child {
          width: 20px;
          height: 15px;
          cursor: pointer;
        }
      }
    }
  }
  .onlineSales_title {
    text-align: center;
    font-size: 4rem;
    margin: 8rem 0 5rem 0;
  }
  .cooperation {
    .table {
      width: 80%;
      margin: auto;
      border: 1px solid #ccc;
      border-right: none;
    }
  }
  .internet {
    > p {
      text-align: center;
      font-size: 4rem;
      margin: 7rem;
    }
    .ul {
      height: 18vw;
      display: flex;
      li {
        width: 33.33333%;
        height: 100%;
        border-right: 1px solid #ccc;
        font-size: 2rem;
        text-align: center;
        > div {
          height: 5rem;
          line-height: 5rem;
          background-color: #003781;
          color: #fff;
          margin-bottom: 1rem;
        }
        p {
          height: 5.5rem;
          line-height: 5.5rem;
        }
      }
    }
  }
  .view_btn {
    > .btn {
      font-size: 2rem;
      display: flex;
      justify-content: center;
      color: #fff;
      > p {
        padding: 1.5rem 3rem;
        background-color: #003781;
        margin: 5rem 1rem;
        cursor: pointer;
      }
    }
    > p {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 6rem;
    }
  }
  .validation {
    width: 100%;
    .validation_box {
      width: 80%;
      margin: auto;
      border-bottom: 1px solid #ccc;
      > p {
        font-size: 1.5rem;
        text-align: center;
      }
      > ul {
        height: 15vw;
        display: flex;
        // border: 1px solid;
        width: 70%;
        margin: 6rem auto 0;
        text-align: center;
        align-items: center;
        > li {
          width: 33.333%;
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
          > div {
            width: 100%;
            height: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            > span {
              width: 8rem;
              height: 8rem;
              border-radius: 50%;
              background-color: #003781;
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
          > p {
            font-size: 2rem;
            width: 75%;
            // margin: 2rem 0;
          }
        }
      }
    }
  }
  .way {
    width: 100%;
    .way_box {
      width: 80%;
      margin: auto;
      > div {
        text-align: center;
        margin: 5rem;
        > p {
          font-size: 4rem;
        }
        > P:nth-child(2) {
          font-size: 1rem;
          margin-top: 3rem;
        }
        > p:last-child {
          font-size: 5rem;
          color: #003781;
          font-weight: 500;
        }
      }
      > img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .phone {
    color: #003781;
    font-weight: 500;
    font-size: 2.5rem;
  }
}

::v-deep.el-table td.el-table__cell div {
  box-sizing: border-box;
  text-align: center;
}
::v-deep.el-table--enable-row-transition .el-table__body td.el-table__cell {
  border-bottom: none;
  border-right: 1px solid #ccc;
}
</style>